import React from "react";

import { useSmartObject } from "@eveworld/contexts";
import { setup } from "../mud/setup.ts";
import moment from "moment";

const { network } = await setup();

const SSUTicketingStatus = React.memo(() => {
  const { smartCharacter } = useSmartObject();

  if (smartCharacter === undefined) return <></>;
  if (smartCharacter.name === "") return <></>;

  const key = {
    smartCharacterId: BigInt(smartCharacter.id),
  };

  const rec = network.useStore
    .getState()
    .getRecord(network.tables.GateTickets, key);

  var dateFormatted = "You do not have a valid ticket for this journey";
  if (rec?.fields.ticketExpiry) {
    var secondsSinceEpoch = Math.floor(Date.now() / 1000);

    var startDate = moment(secondsSinceEpoch * 1000);
    var timeEnd = moment(Number(rec?.fields.ticketExpiry) * 1000);
    var diff = timeEnd.diff(startDate);
    var diffDuration = moment.duration(diff);

    if (diffDuration.seconds() < 0) {
      dateFormatted = "You do not have a valid ticket for this journey.";
    } else {
      dateFormatted =
        "Your ticket expires in " +
        diffDuration.days().toString() +
        "d " +
        diffDuration.hours().toString() +
        "h " +
        diffDuration.minutes().toString() +
        "m " +
        diffDuration.seconds().toString() +
        "s ";
      ("approximately.");
    }
  }

  //const [ticketingStatusMessage] = useState(dateFormatted);

  return <>{dateFormatted}</>;
});

export default React.memo(SSUTicketingStatus);
