import React, { useState } from "react";

import { useSmartObject, useConnection } from "@eveworld/contexts";

const WelcomePlayer = React.memo(() => {
  const { smartCharacter } = useSmartObject();
  useConnection();

  if (smartCharacter === undefined) return <></>;
  if (smartCharacter.name === "") return <></>;

  const [welcomeMessage] = useState("Welcome " + smartCharacter.name);

  return <>{welcomeMessage}</>;
});

export default React.memo(WelcomePlayer);
