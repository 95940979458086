const ISmartUnits = {
    // address: "0x5a5a7689d2baf7e8a74fa73ea47493a0bf4deb2fcac3c962643a02c77c53a744",
    address: "0x74627475686500000000000000000000476174655469636b6574730000000000",
    abi: [
      {
        type: "function",
        name: "tuhe__executeGateTicketsV2",
        inputs: [
          {
            name: "smartCharacterId",
            type: "uint256",
            internalType: "uint256",
          },
          {
            name: "smartStorageUnitId",
            type: "uint256",
            internalType: "uint256",
          },
          {
            name: "quantity",
            type: "uint256",
            internalType: "uint256",
          },
          {
            name: "inventoryItemIdIn",
            type: "uint256",
            internalType: "uint256",
          }
        ],
        outputs: [],
        stateMutability: "nonpayable",
      },
      {
        type: "function",
        name: "tuhe__getTicketExpiry",
        inputs: [
          {
            name: "smartCharacterId",
            type: "uint256",
            internalType: "uint256",
          }
        ],
        outputs: [
          {
            name: "ticketExpiry",
            type: "uint256",
            internalType: "uint256",
          }
        ],
        stateMutability: "nonpayable",
      }
    ]
  } as const;
  
  export default ISmartUnits;
  