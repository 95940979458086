import React, { useContext } from "react";
import { InventoryItem, SmartAssemblyType } from "@eveworld/types";
import { EveButton } from "@eveworld/ui-components";

import { findOwnerByAddress } from "@eveworld/utils";
import { useSmartObject, useConnection } from "@eveworld/contexts";

import { SmartUnitsContext } from "../src/contract/SmartUnitsContext.tsx";

interface Props {
  ephemeralInventoryItem: InventoryItem;
}

const InventoryListItem = ({ ephemeralInventoryItem }: Props) => {
  const { smartCharacter } = useSmartObject();
  const { SmartUnitsContract } = useContext(SmartUnitsContext);
  const { smartAssembly } = useSmartObject();
  const { walletClient, publicClient } = useConnection();

  var errorMessage = "";

  var smartStorageUnit = smartAssembly as SmartAssemblyType<"SmartStorageUnit">;

  var ephemeralInventoryList =
    smartStorageUnit.inventory.ephemeralInventoryList;

  const playerInventory = ephemeralInventoryList.find((x) =>
    findOwnerByAddress(x.ownerId, smartCharacter.address)
  );

  if (smartAssembly === undefined || smartAssembly === null) return <></>;

  const handleGateTickets = async (itemId: number) => {
    const getTransactionRequest = async (
      ephemeralInventoryItem: InventoryItem
    ) => {
      if (
        !walletClient ||
        !walletClient?.account ||
        !publicClient ||
        !SmartUnitsContract
      )
        return;

      return publicClient.simulateContract({
        address: SmartUnitsContract.address,
        abi: SmartUnitsContract.abi,
        functionName: "tuhe__executeGateTicketsV2",
        args: [
          BigInt(smartCharacter.id.toString()),
          BigInt(smartAssembly.id.toString()),
          BigInt(ephemeralInventoryItem.quantity),
          BigInt(itemId),
        ],
        account: walletClient.account.address,
      });
    };

    if (!walletClient || !walletClient.account || !SmartUnitsContract) return;

    if (playerInventory) {
      var ephemeralInventoryItems = playerInventory.ephemeralInventoryItems;
      if (ephemeralInventoryItems) {
        try {
          ephemeralInventoryItems.forEach((ephemeralInventoryItem) => {
            if (
              ephemeralInventoryItem.itemId == itemId &&
              ephemeralInventoryItem.quantity > 0
            ) {
              const request = getTransactionRequest(ephemeralInventoryItem);

              if (!request) return; //return if simulateContract failed

              // console.info(
              //   ephemeralInventoryItem.quantity.toString() +
              //     " of " +
              //     ephemeralInventoryItem.itemId.toString()
              // );

              if (walletClient.account) {
                walletClient.writeContract({
                  address: SmartUnitsContract.address,
                  abi: SmartUnitsContract.abi,
                  functionName: "tuhe__executeGateTicketsV2",
                  args: [
                    BigInt(smartCharacter.id.toString()),
                    BigInt(smartAssembly.id.toString()),
                    BigInt(ephemeralInventoryItem.quantity),
                    BigInt(itemId),
                  ],
                  account: walletClient.account.address,
                  chain: walletClient.chain,
                });
              }
            }
          });

          errorMessage =
            "Process complete, you may now proceed to the nearest jump gate";
          //console.info(errorMessage);

          return;
        } catch (e: any) {
          errorMessage = e.message;
          // console.error(errorMessage);
        }
      }
    }
  };

  let button;
  if (ephemeralInventoryItem.name == "Salt") {
    button = (
      <EveButton
        typeClass="primary"
        className="primary-sm"
        style={{
          width: "175px",
        }}
        onClick={() => handleGateTickets(ephemeralInventoryItem.itemId)}
        disabled={false}
        id="ClaimMinutes"
      >
        Claim {Math.floor(ephemeralInventoryItem.quantity)} Minutes of Gate
        Access
      </EveButton>
    );
  }

  return (
    <>
      <tr style={{ width: "100%" }}>
        <td style={{ width: "80px", height: "80px", paddingLeft: "15px" }}>
          <img
            src={ephemeralInventoryItem.image.replace(
              "https://sandbox-garnet-ipfs-gateway.nursery.reitnorf.com/ipfs/",
              "https://mainnet-game-ipfs-gateway.nursery.reitnorf.com/ipfs/"
            )}
          ></img>
        </td>
        <td
          style={{
            width: "calc(100% - 200px)",
            height: "80px",
            paddingLeft: "15px",
          }}
        >
          {ephemeralInventoryItem.quantity} units of{" "}
          {ephemeralInventoryItem.name}
        </td>
        <td>{button}</td>
      </tr>
      <tr>
        <td>
          <hr></hr>
        </td>
        <td>
          <hr></hr>
        </td>
        <td>
          <hr></hr>
        </td>
      </tr>
    </>
  );
};

export default InventoryListItem;
