import React, { useEffect } from "react";

import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "../../../src/components/ui/card.tsx";
import {
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
} from "../../../src/components/ui/tabs.tsx";
import { Cpu } from "lucide-react";

import { useSmartObject, useNotification } from "@eveworld/contexts";
import { Severity, SmartAssemblyType } from "@eveworld/types";
import { ErrorNotice, ErrorNoticeTypes } from "@eveworld/ui-components";

import { JSX } from "react/jsx-runtime";
import { findOwnerByAddress } from "@eveworld/utils";

import WelcomePlayer from "./WelcomePlayer.tsx";
import SSUTicketingStatus from "./SSUTicketingStatus.tsx";
import InventoryListItem from "./InventoryListItem.tsx";
import InventoryListView from "./InventoryListView.tsx";

const SSUTicketingMachine = React.memo((): JSX.Element => {
  const { smartAssembly, smartCharacter, loading } = useSmartObject();
  const { notify, handleClose } = useNotification();

  useEffect(() => {
    if (loading) {
      notify({ type: Severity.Info, message: "Loading..." });
    } else {
      handleClose();
    }
  }, [loading]);

  if ((!loading && !smartAssembly) || !smartAssembly?.isValid) {
    return <ErrorNotice type={ErrorNoticeTypes.SMART_ASSEMBLY} />;
  }

  var smartSU = smartAssembly as SmartAssemblyType<"SmartStorageUnit">;

  var smartStorageUnit = smartAssembly as SmartAssemblyType<"SmartStorageUnit">;

  var ephemeralInventoryList =
    smartStorageUnit.inventory.ephemeralInventoryList;

  const playerInventory = ephemeralInventoryList.find((x) =>
    findOwnerByAddress(x.ownerId, smartCharacter.address)
  );

  const inventoryList: JSX.Element[] = [];
  if (playerInventory) {
    var ephemeralInventoryItems = playerInventory.ephemeralInventoryItems;
    if (ephemeralInventoryItems && ephemeralInventoryItems.length > 0) {
      ephemeralInventoryItems.forEach((ephemeralInventoryItem) => {
        inventoryList.push(
          <InventoryListItem
            ephemeralInventoryItem={ephemeralInventoryItem}
            key={ephemeralInventoryItem.itemId}
          ></InventoryListItem>
        );
      });
    } else {
      inventoryList.push(
        <CardDescription
          style={{ marginTop: "10px" }}
          key="ephemeralInventoryItemsNotSet"
        >
          You have no items in this SSU, deposit salt and claim your jump gate
          ticket now
        </CardDescription>
      );
    }
  } else {
    inventoryList.push(
      <CardDescription
        style={{ marginTop: "10px" }}
        key="ephemeralInventoryItemsNotSet"
      >
        You have no items in this SSU, deposit salt and claim your jump gate
        ticket now
      </CardDescription>
    );
  }

  return (
    <div className="container mx-auto py-0 px-0">
      <Card className="text-3xl font-bold text-primary mb-3">
        <CardHeader>
          <CardTitle>{smartSU.name}</CardTitle>
          <CardDescription>
            <WelcomePlayer></WelcomePlayer>
          </CardDescription>
        </CardHeader>
      </Card>

      <Tabs defaultValue="tickets" className="max-w-4xl mx-auto">
        <TabsList className="grid w-full grid-cols-2 mb-8">
          <TabsTrigger value="tickets">Tickets</TabsTrigger>
          <TabsTrigger value="instructions">Instructions</TabsTrigger>
        </TabsList>

        <TabsContent value="tickets" className="grid md:grid-cols-1 gap-4">
          <Card>
            <CardHeader className="space-y-1">
              <div className="w-8 h-8 mb-2 text-primary">
                <Cpu className="w-8 h-8" />
              </div>
              <CardTitle>Access Status:</CardTitle>
              <CardDescription>
                <SSUTicketingStatus></SSUTicketingStatus>
              </CardDescription>
            </CardHeader>
            <CardContent>
              <div className="text-2xl font-bold text-primary">
                Your Inventory
              </div>
              <InventoryListView
                inventoryList={inventoryList}
              ></InventoryListView>
            </CardContent>
          </Card>
        </TabsContent>

        <TabsContent value="instructions" className="space-y-4">
          <Card>
            <CardHeader>
              <CardTitle>Instructions</CardTitle>
              <CardDescription>
                Access time is paid for with Salt. Drop some salt into the left
                hand Inventory area, and then click on the Claim button.
              </CardDescription>
              <CardDescription>
                Corp members will receive double gate access duration
                automatically o7
              </CardDescription>
            </CardHeader>
          </Card>
        </TabsContent>
      </Tabs>
    </div>
  );
});

export default React.memo(SSUTicketingMachine);
