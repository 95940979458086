import React, { useEffect, useContext, useState } from "react";

import { Button } from "../../../src/components/ui/button";
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "../../../src/components/ui/card";
// import { Input } from "../../../src/components/ui/input";
// import { Label } from "../../../src/components/ui/label";
import {
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
} from "../../../src/components/ui/tabs";
import { ScrollArea } from "../../../src/components/ui/scroll-area";
// import { Slider } from "../../../src/components/ui/slider";
// import { Switch } from "../../../src/components/ui/switch";
// import { Progress } from "../../../src/components/ui/progress";
import { Cpu, Shield, Zap, MonitorSmartphone } from "lucide-react";

import {
  useSmartObject,
  useNotification,
  useConnection,
} from "@eveworld/contexts";
import { Severity, SmartAssemblyType } from "@eveworld/types";
import {
  //EveLoadingAnimation,
  ErrorNotice,
  ErrorNoticeTypes,
  // EveButton,
} from "@eveworld/ui-components";

import { JSX } from "react/jsx-runtime";

//import { SmartGatesContext } from "../src/contract/SmartGatesContext.tsx";
//import moment from "moment";

// import { useMUD } from "../MUDContext.tsx";
//import { setup } from "../mud/setup.ts";
import WelcomePlayer from "./WelcomePlayer.tsx";
import SSUTicketingStatus from "./SSUTicketingStatus.tsx";

//const { network } = await setup();

const SmartGateView2 = React.memo((): JSX.Element => {
  //const { SmartGatesContract } = useContext(SmartGatesContext);
  const { smartAssembly, smartCharacter, loading } = useSmartObject();
  const { notify, handleClose } = useNotification();
  //const { walletClient, publicClient } = useConnection();

  //const [progress, setProgress] = useState(45);

  useEffect(() => {
    if (loading) {
      notify({ type: Severity.Info, message: "Loading..." });
    } else {
      handleClose();
    }
  }, [loading]);

  if (smartCharacter === undefined) return <></>;
  if (smartCharacter.name === "") return <></>;

  if ((!loading && !smartAssembly) || !smartAssembly?.isValid) {
    return <ErrorNotice type={ErrorNoticeTypes.SMART_ASSEMBLY} />;
  }

  var smartGate = smartAssembly as SmartAssemblyType<"SmartGate">;

  // const key = {
  //   smartCharacterId: BigInt(smartCharacter.id),
  // };

  // console.log(smartCharacter.name);

  // const rec = network.useStore
  //   .getState()
  //   .getRecord(network.tables.GateTickets, key);

  // var dateFormatted = "You do not have a valid ticket for this journey.";
  // if (rec?.fields.ticketExpiry) {
  //   var secondsSinceEpoch = Math.floor(Date.now() / 1000);

  //   var startDate = moment(secondsSinceEpoch * 1000);
  //   var timeEnd = moment(Number(rec?.fields.ticketExpiry) * 1000);
  //   var diff = timeEnd.diff(startDate);
  //   var diffDuration = moment.duration(diff);

  //   if (diffDuration.seconds() < 0) {
  //     dateFormatted = "You do not have a valid ticket for this journey.";
  //   } else {
  //     dateFormatted =
  //       "Your ticket expires in " +
  //       diffDuration.days().toString() +
  //       "d " +
  //       diffDuration.hours().toString() +
  //       "h " +
  //       diffDuration.minutes().toString() +
  //       "m " +
  //       diffDuration.seconds().toString() +
  //       "s ";
  //     ("approximately.");
  //   }
  // }

  // const handleCouldAccess = async () => {
  //   const getTransactionRequest = async () => {
  //     if (
  //       !walletClient ||
  //       !walletClient?.account ||
  //       !publicClient ||
  //       !SmartGatesContract ||
  //       !smartAssembly
  //     )
  //       return;

  //     return publicClient.simulateContract({
  //       address: SmartGatesContract.address,
  //       abi: SmartGatesContract.abi,
  //       functionName: "tuhe__executeGateAccess",
  //       args: [BigInt(smartGate.id.toString()), true],
  //       account: walletClient.account.address,
  //     });
  //   };

  //   if (
  //     !walletClient ||
  //     !walletClient.account ||
  //     !SmartGatesContract ||
  //     !smartAssembly
  //   )
  //     return;

  //   try {
  //     const request = getTransactionRequest();

  //     if (!request) return; //return if simulateContract failed

  //     if (walletClient.account) {
  //       walletClient.writeContract({
  //         address: SmartGatesContract.address,
  //         abi: SmartGatesContract.abi,
  //         functionName: "tuhe__executeGateAccess",
  //         args: [BigInt(smartGate.id.toString()), true],
  //         account: walletClient.account.address,
  //         chain: walletClient.chain,
  //       });
  //     }

  //     return;
  //   } catch (e: any) {
  //     //errorMessage = e.message;
  //     console.error(e);
  //   }
  // };

  return (
    <div className="container mx-auto py-0 px-0">
      <Card className="text-3xl font-bold text-primary mb-3">
        <CardHeader>
          <CardTitle>{smartGate.name}</CardTitle>
          <CardDescription>
            <WelcomePlayer></WelcomePlayer>
          </CardDescription>
        </CardHeader>
      </Card>

      <Tabs defaultValue="access" className="max-w-4xl mx-auto">
        <TabsList className="grid w-full grid-cols-2 mb-8">
          <TabsTrigger value="access">Access</TabsTrigger>
          <TabsTrigger value="instructions">Instructions</TabsTrigger>
        </TabsList>

        <TabsContent value="access" className="grid md:grid-cols-1 gap-4">
          <Card>
            <CardHeader className="space-y-1">
              <div className="w-8 h-8 mb-2 text-primary">
                <Cpu className="w-8 h-8" />
              </div>
              <CardTitle>Access Status:</CardTitle>
              <CardDescription>
                <SSUTicketingStatus></SSUTicketingStatus>
              </CardDescription>
            </CardHeader>
          </Card>
        </TabsContent>

        <TabsContent value="instructions" className="space-y-4">
          <Card>
            <CardHeader>
              <CardTitle>Instructions</CardTitle>
              <CardDescription>
                If you do not have a valid ticket to use this Smart Gate, head
                over to the nearby Ticket Machine.
              </CardDescription>
              <CardDescription>
                You will need to bring Salt to exchange for tickets.
              </CardDescription>
            </CardHeader>
          </Card>
        </TabsContent>
      </Tabs>
    </div>
  );
});

export default React.memo(SmartGateView2);
