import React from "react";
import { CardTitle } from "../../../src/components/ui/card";

interface Props {
  inventoryList: JSX.Element[];
}

const InventoryListView = ({ inventoryList }: Props) => {
  return (
    <>
      <CardTitle>
        You have the following items safely stored in this SSU:
      </CardTitle>
      <div className="contents empty-span-full text-xs">
        <table style={{ width: "100%" }}>
          <tbody>{inventoryList}</tbody>
        </table>
      </div>
    </>
  );
};

export default React.memo(InventoryListView);
