import React, { useEffect, useContext } from "react";
import { useMUD } from "../MUDContext";

import { useSmartObject, useNotification } from "@eveworld/contexts";
import { Severity, SmartAssemblyType } from "@eveworld/types";
import {
  EveLoadingAnimation,
  ErrorNotice,
  ErrorNoticeTypes,
} from "@eveworld/ui-components";

const Products = React.memo(() => {
  const { smartAssembly, smartCharacter, loading } = useSmartObject();
  const { notify, handleClose } = useNotification();
  const {
    network: { tables, useStore },
  } = useMUD();

  if (smartCharacter === undefined) return <></>;
  if (smartCharacter.name === "") return <></>;

  const gateTickets = useStore((state) => {
    var records = Object.values(state.getRecords(tables.GateTickets));
    //records.sort((a, b) => bigIntSort(b.fields.points, a.fields.points)); //b then a to sort descending
    return records;
  });

  //console.log(gateTickets.length);

  useEffect(() => {
    if (loading) {
      notify({ type: Severity.Info, message: "Loading..." });
    } else {
      handleClose();
    }
  }, [loading]);

  if ((!loading && !smartAssembly) || !smartAssembly?.isValid) {
    return <ErrorNotice type={ErrorNoticeTypes.SMART_ASSEMBLY} />;
  }

  return (
    <>
      <div className="grid grid-cols-2 mobile:grid-cols-1 bg-crude">
        <div className="text-l my-2 text-center">Welcome Stranger!</div>
      </div>
    </>
  );
});

export default React.memo(Products);
