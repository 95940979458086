import "./App.css";
import "./index.css";

import { ErrorBoundary } from "react-error-boundary";
import { createBrowserRouter, RouterProvider } from "react-router-dom";

import { EveWorldProvider } from "@eveworld/contexts";
import { ErrorNotice, ErrorNoticeTypes } from "@eveworld/ui-components";

import DefaultView from "./components/DefaultView";
// import ProductsView from "./components/ProductsView";
// import FriendsView from "./components/FriendsView";
// import SSUView from "./components/SSUView";
import SSUTicketingMachine from "./components/SSUTicketingMachine";
// import SmartGateView from "./components/SmartGateView";
import SmartGateView2 from "./components/SmartGateView2";

import { StrictMode } from "react";
import { createRoot } from "react-dom/client";
import { App } from "./App";
import { MUDProvider } from "./MUDContext";
import SmartUnitsProvider from "./src/contract/SmartUnitsContext";
import { setup } from "./mud/setup";
import React from "react";

const router = createBrowserRouter([
  {
    element: <App />,
    children: [
      // {
      //   path: "/products",
      //   element: <ProductsView />,
      // },
      // {
      //   path: "/friends",
      //   element: <FriendsView />,
      // },
      // {
      //   path: "/ssuview",
      //   element: <SSUView />,
      // },
      {
        path: "/ssuticketingmachine",
        element: <SSUTicketingMachine />,
      },
      // {
      //   path: "/smartgate",
      //   element: <SmartGateView />,
      // },
      {
        path: "/smartgate2",
        element: <SmartGateView2 />,
      },
      {
        path: "*",
        element: <DefaultView />,
      },
    ],
  },
]);

setup().then(async (result) => {
  createRoot(document.getElementById("root")!).render(
    <ErrorBoundary
      fallback={
        <ErrorNotice
          type={ErrorNoticeTypes.MESSAGE}
          errorMessage="Anomaly detected: See developer console for details"
        />
      }
    >
      <EveWorldProvider>
        <SmartUnitsProvider>
          <MUDProvider value={result}>
            <StrictMode>
              <RouterProvider router={router} />
            </StrictMode>
          </MUDProvider>
        </SmartUnitsProvider>
      </EveWorldProvider>
      ,
    </ErrorBoundary>
  );
});
